import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  DynamicTooltipProps,
  DynamicTooltipRelativePlacement,
  DynamicTooltipTargetNodeSelector,
} from '../../../components/application/ai/AiBot/core/DynamicTooltip/types';
import {
  InterceptorSceneKey,
  RenderingStrategy,
  StepKeys,
} from '../../../components/application/ai/AiBot/shared/types';

interface State {
  renderingStrategy: RenderingStrategy;
  shouldCongratsAnimationRun: boolean;
  shouldRunStepCompletionAnimation: boolean;
  activeStep: StepKeys;
  currentObservingStepKey: StepKeys;
  interceptorSceneKey?: InterceptorSceneKey;
  shouldRunInterceptorPerformCallback: boolean;
  isAiBotHidden: boolean;
  displayTextarea: boolean;
  skipTyping: boolean;
  dynamicTooltipProps: DynamicTooltipProps;
  shouldShowSummaryScreen: boolean;
  shouldBreakTextareaOpening: boolean;
}

const dynamicTooltipInitialProps: DynamicTooltipProps = {
  shouldRender: false,
  targetNodeSelector: DynamicTooltipTargetNodeSelector.BuildOrgChartButton,
  placement: DynamicTooltipRelativePlacement.Top,
  title: '',
  description: '',
};

const initialState: State = {
  renderingStrategy: RenderingStrategy.Closed,
  shouldCongratsAnimationRun: false,
  shouldRunStepCompletionAnimation: false,
  //TODO: Replace with real key
  activeStep: StepKeys.BuildAccount,
  currentObservingStepKey: StepKeys.BuildAccount,
  shouldRunInterceptorPerformCallback: false,
  isAiBotHidden: false,
  displayTextarea: true,
  skipTyping: false,
  dynamicTooltipProps: dynamicTooltipInitialProps,
  shouldShowSummaryScreen: false,
  shouldBreakTextareaOpening: false,
};

const aiBotSlice = createSlice({
  name: 'aiBotSlice',
  initialState,
  reducers: {
    setRenderingStrategy(state, action: PayloadAction<RenderingStrategy>) {
      state.renderingStrategy = action.payload;
    },
    runCongratsAnimation(state) {
      state.shouldCongratsAnimationRun = true;
    },
    resetCongratsAnimation(state) {
      state.shouldCongratsAnimationRun = false;
    },
    runStepCompletionAnimation(state) {
      state.shouldRunStepCompletionAnimation = true;
    },
    resetStepCompletionAnimation(state) {
      state.shouldRunStepCompletionAnimation = false;
    },
    setActiveStep(state, action: PayloadAction<StepKeys>) {
      state.activeStep = action.payload;
    },
    setCurrentObservingStepKey(state, action: PayloadAction<StepKeys>) {
      state.currentObservingStepKey = action.payload;
    },
    runInterceptorScene(state, action: PayloadAction<InterceptorSceneKey>) {
      state.interceptorSceneKey = action.payload;
    },
    runInterceptorSceneWithAfterPerformCallback(state, action: PayloadAction<InterceptorSceneKey>) {
      state.interceptorSceneKey = action.payload;
      state.shouldRunInterceptorPerformCallback = true;
    },
    resetInterceptorAfterPerformCallback(state) {
      state.shouldRunInterceptorPerformCallback = false;
    },
    resetInterceptorScene(state) {
      state.interceptorSceneKey = undefined;
    },
    setDisplayTextarea(state) {
      state.displayTextarea = true;
    },
    setHideTextarea(state) {
      state.displayTextarea = false;
    },
    renderDynamicTooltip(state, action: PayloadAction<DynamicTooltipProps>) {
      state.dynamicTooltipProps = action.payload;
    },
    resetDynamicTooltip(state) {
      state.dynamicTooltipProps.shouldRender = false;
    },
    hideAiBot(state) {
      state.isAiBotHidden = true;
    },
    showAiBot(state) {
      state.isAiBotHidden = false;
    },
    setSkipTyping(state, action: PayloadAction<boolean>) {
      state.skipTyping = action.payload;
    },
    showSummaryScreen(state) {
      state.shouldShowSummaryScreen = true;
    },
    hideSummaryScreen(state) {
      state.shouldShowSummaryScreen = false;
    },
    breakTextareaOpening(state) {
      state.shouldBreakTextareaOpening = true;
    },
    allowTextareaOpening(state) {
      state.shouldBreakTextareaOpening = false;
    },
    resetAiBotSlice() {
      return initialState;
    },
  },
});

export const {
  setRenderingStrategy,
  runCongratsAnimation,
  resetCongratsAnimation,
  runStepCompletionAnimation,
  resetStepCompletionAnimation,
  setActiveStep,
  setCurrentObservingStepKey,
  runInterceptorScene,
  resetInterceptorScene,
  setDisplayTextarea,
  setHideTextarea,
  runInterceptorSceneWithAfterPerformCallback,
  resetInterceptorAfterPerformCallback,
  renderDynamicTooltip,
  resetDynamicTooltip,
  hideAiBot,
  showAiBot,
  showSummaryScreen,
  hideSummaryScreen,
  breakTextareaOpening,
  allowTextareaOpening,
  resetAiBotSlice,
  setSkipTyping,
} = aiBotSlice.actions;
export default aiBotSlice.reducer;
