import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  superShareModalCurriculumId: number | null;
  superShareModalVisible: boolean;
}

const initialState: State = {
  superShareModalCurriculumId: null,
  superShareModalVisible: false,
};

const contentLibrarySlice = createSlice({
  name: 'contentLibrarySlice',
  initialState,
  reducers: {
    setSuperShareModalCurriculumId(state, action: PayloadAction<number | null>) {
      state.superShareModalCurriculumId = action.payload;
    },
    setSuperShareModalVisible(state, action: PayloadAction<boolean>) {
      state.superShareModalVisible = action.payload;
    },
  },
});

export const { setSuperShareModalCurriculumId, setSuperShareModalVisible } =
  contentLibrarySlice.actions;
export default contentLibrarySlice.reducer;
