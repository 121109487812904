import { JSONContent, generateJSON } from '@tiptap/core';
import { PaletteBoard } from 'saguaro';

import { ReactComponent as GiphyIcon } from '../../../../images/gif_icon.svg';
import { ReactComponent as StockImageIcon } from '../../../../images/stock_image_icon.svg';
import { ReactComponent as UploadImageIcon } from '../../../../images/upward_arrow_icon.svg';
import { MenuItem } from '../components/MediaFlyoutMenu/MediaFlyoutMenu';
import GifInput from '../plugins/Image/gif/GifInput';
import ImageInput from '../plugins/Image/ImageInput';
import StockImageInput from '../plugins/Image/stock_image/StockImageInput';
import { EditorExtensions } from './constants/extensions';

export const replaceCaptureSrc = (src: string, enableEdit: boolean): string => {
  // Ensure '?iframe=1' exists
  if (!src.includes('?iframe=1')) {
    src += '?iframe=1';
  }

  const editParam = '&edit=1';

  // Add or remove '&edit=1' based on enableEdit flag
  if (enableEdit) {
    if (!src.includes(editParam)) {
      src += editParam;
    }
  } else {
    if (src.includes(editParam)) {
      src = src.replace(editParam, '');
    }
  }

  return src;
};

export const prependHttps = (url: string | null) => {
  if (!url) return null;

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url;
  } else {
    return url;
  }
};

export type ImageMenuItems = 'upload' | 'giphy' | 'stock_image';

const staticImageFlyoutItems: Record<ImageMenuItems, MenuItem> = {
  upload: {
    Component: ImageInput,
    SvgIcon: UploadImageIcon,
    title: 'upload',
    id: 'upload',
  },
  giphy: {
    Component: GifInput,
    SvgIcon: GiphyIcon,
    title: 'giphy',
    id: 'giphy',
  },
  stock_image: {
    Component: StockImageInput,
    SvgIcon: StockImageIcon,
    title: 'stock_image',
    id: 'stock_image',
  },
};

export const getImageFlyoutItems = (menuItems: ImageMenuItems[], t: (key: string) => string) => {
  return menuItems.map((menuItem) => ({
    ...staticImageFlyoutItems[menuItem],
    title: t(staticImageFlyoutItems[menuItem].title),
  }));
};

export const isColorWhite = (color: string | undefined) => {
  if (!color) return false;

  const whiteRegex = /^#(?:[fF]{3}|[fF]{6})$/;
  return whiteRegex.test(color);
};

const emptyContent: JSONContent = { type: 'doc', content: [] };

export const convertContentJson = (
  content: JSONContent | string | null,
  palettes: PaletteBoard
): JSONContent => {
  if (!content) return emptyContent;

  if (typeof content === 'string') {
    return generateJSON(content, EditorExtensions({ isEditable: false, palettes }));
  }

  return content;
};
