import { IconName } from '@fortawesome/fontawesome-svg-core';

import { MetaUserAccess } from '../../../types/Curriculum';
import { routes } from '../publicApplication/applicationRouter';

type ChevronDirectionProps = {
  isActive: boolean;
  initialDirection: 'up' | 'right';
};

export const getChevronIconName = ({
  isActive,
  initialDirection,
}: ChevronDirectionProps): IconName => {
  return `chevron-${isActive ? initialDirection : 'down'}`;
};

export const viewableUserAccess = (userAccess: MetaUserAccess) => {
  if (!userAccess) return false;

  return ['view', 'discoverable', 'assigned'].includes(userAccess);
};

export const getSurveyUrlFromAccess = (
  userAccess: MetaUserAccess | undefined,
  curriculumId: number,
  slug: string,
  elementId: number
) => {
  if (!userAccess || userAccess === 'pending') {
    return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
  } else if (viewableUserAccess(userAccess)) {
    return routes.surveyConsume({ slug, id: elementId }).href;
  } else {
    return routes.surveyEditor({ slug, id: elementId }).href;
  }
};
