export enum DynamicTooltipTargetNodeSelector {
  BuildOrgChartButton = '#build-org-chart-button',
  Curriculum = '[class*="LibraryRow"]',
  ShareContentSearchField = '[class^="SuperShareModal__Wrapper"]',
}

export enum DynamicTooltipRelativePlacement {
  Top = 'top',
  Bottom = 'bottom',
}

export interface DynamicTooltipAbsolutePosition {
  top: number;
  left: number;
}

export interface DynamicTooltipProps {
  shouldRender: boolean;
  targetNodeSelector: DynamicTooltipTargetNodeSelector;
  placement: DynamicTooltipRelativePlacement;
  title: string;
  description: string;
}
