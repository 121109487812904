import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  page: number;
  searchValue: string;
  selectedUsers: number[];
  unselectedUsers: number[];
  allUsersSelected: boolean;
  searchClear: boolean;
}

const initialState: State = {
  page: 1,
  searchValue: '',
  selectedUsers: [],
  unselectedUsers: [],
  allUsersSelected: false,
  searchClear: false,
};

const curriculumCompletionsSlice = createSlice({
  name: 'curriculumCompletionsSlice',
  initialState,
  reducers: {
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
      state.page = 1;
      state.allUsersSelected = false;
      state.selectedUsers = [];
      state.unselectedUsers = [...state.unselectedUsers, ...state.selectedUsers];
      state.searchClear = !action.payload;
    },
    toggleUserSelect(state, action: PayloadAction<number>) {
      const userId = action.payload;
      const isSelected = state.selectedUsers.includes(userId);
      const selectedUsers = isSelected
        ? state.selectedUsers.filter((id) => id !== userId)
        : [...state.selectedUsers, userId];
      const unselectedUsers = isSelected
        ? [...state.unselectedUsers, userId]
        : state.unselectedUsers.filter((id) => id !== userId);

      state.selectedUsers = selectedUsers;
      state.unselectedUsers = unselectedUsers;
      state.allUsersSelected = isSelected ? false : unselectedUsers.length === 0;
    },
    selectAllUsers(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        return {
          ...state,
          allUsersSelected: true,
          selectedUsers: [...state.unselectedUsers, ...state.selectedUsers].filter(
            (value, index, array) => array.indexOf(value) === index
          ),
          unselectedUsers: [],
        };
      } else {
        return {
          ...state,
          allUsersSelected: false,
          selectedUsers: [],
          unselectedUsers: [...state.unselectedUsers, ...state.selectedUsers].filter(
            (value, index, array) => array.indexOf(value) === index
          ),
        };
      }
    },
    setAllIds(state, action: PayloadAction<number[]>) {
      state.unselectedUsers = action.payload;
    },
    resetSearchClear(state) {
      state.searchClear = false;
    },
  },
});

export const {
  changePage,
  toggleUserSelect,
  selectAllUsers,
  setAllIds,
  resetSearchClear,
  setSearchValue,
} = curriculumCompletionsSlice.actions;
export default curriculumCompletionsSlice.reducer;
