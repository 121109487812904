import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

import Icon from '../../display/icons/Icon';
import { Role, Type } from './AssistiveTextTypes';
import { AssistiveTextWrapper, IconWrapper, Span, Text } from './styles';
import { Props } from './types';

export const getTextType = (type: Type, vars: { [key: string]: string }) => {
  switch (type) {
    case 'help':
      return vars.textSubdued;
    case 'error':
      return vars.stateError;
  }
};

const AssistiveText = ({ id, text, type = 'help', wrapErrors = false }: Props) => {
  let iconName: IconName | undefined;
  let role: Role;

  switch (type) {
    case 'error':
      iconName = 'times-circle';
      role = 'alert';
      break;
    case 'help':
      // iconName is undefined for 'help'
      role = '';
      break;
  }

  return (
    <AssistiveTextWrapper className='translate'>
      <Span type={type}>
        {iconName && (
          <IconWrapper>
            <Icon name={iconName} size='2xs' weight='solid' />
          </IconWrapper>
        )}
        <Text addMarginForIcon={!!iconName} id={id} role={role} wrapErrors={wrapErrors}>
          {text}
        </Text>
      </Span>
    </AssistiveTextWrapper>
  );
};

export default AssistiveText;
