import { darken } from 'polished';
import { PaletteBoard } from 'saguaro';
import { css } from 'styled-components';

import { ContentStyleGenericTag, ContentStyles } from '../../../types/ContentStyle';
import { paletteColorDecoder } from '../../design_system/helpers';
import { fontSm4 } from '../../styled/TypeSystem';

/**
 * The brand style for all tags should be present for all accounts. However, to ensure safety, we are handling missing
 * tags gracefully to prevent page the page from failing to render due to missing tags in the branding style json.
 */
const tagStyle = (tagContentStyle: ContentStyleGenericTag | undefined, palettes: PaletteBoard) => {
  return (
    tagContentStyle &&
    css`
      font-family: ${tagContentStyle.fontFamily}, Poppins, arial, sans-serif;
      font-size: ${tagContentStyle.fontSize};
      font-weight: ${tagContentStyle.fontWeight};
      text-decoration: ${tagContentStyle.textDecoration};
      font-style: ${tagContentStyle.fontStyle};
      text-align: ${tagContentStyle.textAlign};
      color: ${paletteColorDecoder(palettes, tagContentStyle.color)} !important;
    `
  );
};

export default css<{ $contentStyles: ContentStyles }>`
  ${({ $contentStyles, theme: { vars, palettes, constants } }) => {
    if (!$contentStyles) return '';
    const { a, p, h1, h2, h3, h4, hr } = $contentStyles;
    const fontSize = p?.fontSize.replace('px', '');
    const fontSizeNumber = fontSize ? parseInt(fontSize) : 16;
    const listMarkerSpacing = (fontSizeNumber * 2) / 16;

    return css`
      h1,
      h2,
      h3,
      h4,
      p,
      tr,
      li {
        a {
          color: ${paletteColorDecoder(palettes, a?.color)};
        }
      }

      h1 {
        ${tagStyle(h1, palettes)};
      }

      h2 {
        ${tagStyle(h2, palettes)};
      }

      h3 {
        ${tagStyle(h3, palettes)};
      }

      h4 {
        ${tagStyle(h4, palettes)};
      }

      p {
        ${tagStyle(p, palettes)};
      }

      li {
        ${tagStyle(p, palettes)};
      }

      a {
        ${tagStyle(a, palettes)};
      }

      ul[data-type='taskList'] {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          align-items: flex-start;

          > div {
            flex: 1 1 auto;
          }

          p {
            margin-bottom: 1em; /* This is based on the default margin-bottom of p tags that is being removed in the node */
          }

          > label {
            margin: ${constants.spacerSm1} ${constants.spacerSm3} 0 0;
            user-select: none;
          }
        }
      }

      s {
        span {
          text-decoration: line-through;
          text-decoration-color: currentcolor;
        }

        u {
          span {
            text-decoration: underline line-through;
            text-decoration-color: currentcolor;
          }
        }
      }

      u {
        span {
          text-decoration: underline;
          text-decoration-color: currentcolor;
        }
      }

      ul[data-type='taskList'] li[data-checked] div > p > span {
        line-height: 1.25rem;
        overflow: hidden;
      }

      /* Reset styles for ol and ul to ensure numbers and bullets are displayed */
      ol > li,
      ul > li {
        display: list-item;
        align-items: initial;
      }

      ${a?.color &&
      css`
        a {
          &:hover,
          &:active {
            color: ${darken(0.1, paletteColorDecoder(palettes, a?.color))};
          }
        }
      `}

      hr {
        border-top: solid;
        border-width: ${hr?.borderWidth};
        border-color: ${paletteColorDecoder(palettes, hr?.borderColor)};
      }

      [data-type='emoji'] {
        img {
          /* em needs to be used here to display the emojis properly in the editor */
          width: 1em;
          height: 1em;
        }
      }

      pre {
        background: #0d0d0d;
        border-radius: ${constants.borderRadiusLg};
        color: #fff;
        font-family: 'JetBrainsMono', monospace;
        padding: ${constants.spacerMd2} ${constants.spacerMd3};

        code {
          background: none;
          color: inherit;
          padding: 0;
          ${fontSm4};
        }

        .hljs-comment,
        .hljs-quote {
          color: #616161;
        }

        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-selector-id,
        .hljs-selector-class {
          color: #f98181;
        }

        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params {
          color: #fbbc88;
        }

        .hljs-string,
        .hljs-symbol,
        .hljs-bullet {
          color: #b9f18d;
        }

        .hljs-title,
        .hljs-section {
          color: #faf594;
        }

        .hljs-keyword,
        .hljs-selector-tag {
          color: #70cff8;
        }

        .hljs-emphasis {
          font-style: italic;
        }

        .hljs-strong {
          font-weight: ${constants.fontBold};
        }
      }

      ul {
        padding-left: ${listMarkerSpacing}em;
      }

      ol {
        list-style-type: decimal;
        padding-left: ${listMarkerSpacing}em;
      }

      ol > li > ol {
        list-style-type: lower-alpha;
      }

      ol > li > ol > li > ol {
        list-style-type: lower-roman;
      }

      blockquote {
        border-left: ${constants.borderWidthXl} solid ${vars.accentPrimaryDefault};
        padding-left: ${constants.spacerSm2};
      }
    `;
  }}
`;
