import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import OutsideClickHandler from '../../../../design_system/OutsideClickHandler';
import Poppable from '../../../../Poppable';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { TOOLBAR_Z_INDEX } from '../../shared/styles';
import AlignmentDropdown from './EmbedOptionsBarItems/AlignmentDropdown';
import CopyNodeButton from './EmbedOptionsBarItems/CopyNodeButton';
import DeleteButton from './EmbedOptionsBarItems/DeleteButton';
import ImageAltText from './EmbedOptionsBarItems/ImageAltText';
import ImageLinkButton from './EmbedOptionsBarItems/ImageLinkButton';
import OpenLink from './EmbedOptionsBarItems/OpenLink';
import { EMBED_BAR_HEIGHT } from './EmbedOptionsBarItems/shared/constants';
import { MenuProps } from './EmbedOptionsBarItems/shared/Dropdown';
import SizeDropdown from './EmbedOptionsBarItems/SizeDropdown';
import StylesDropdown from './EmbedOptionsBarItems/StylesDropdown';
import ViewDropdown from './EmbedOptionsBarItems/ViewDropdown';
import WidthDropdown from './EmbedOptionsBarItems/WidthDropdown';
import ThreeDotButton from './ThreeDotButton';

const BarWrapper = styled.div`
  --height: ${EMBED_BAR_HEIGHT};
  display: flex;
  width: max-content;
  height: var(--height);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
  z-index: ${TOOLBAR_Z_INDEX + 2};

  > * {
    border: none;
    padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
    color: ${({ theme: { vars } }) => vars.textDefault} !important;
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
    cursor: pointer;

    :hover {
      background-color: ${({ theme: { vars } }) => vars.foundationHover};
    }

    :first-child {
      border-radius: ${({ theme: { constants } }) =>
        `${constants.borderRadiusMd} 0 0 ${constants.borderRadiusMd}`};
    }

    :last-child {
      border-radius: ${({ theme: { constants } }) =>
        `0 ${constants.borderRadiusMd} ${constants.borderRadiusMd} 0`};
    }

    :not(:last-child) {
      border-right: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
    }

    font-weight: ${({ theme: { constants } }) => constants.fontLight};
    ${fontSm5};
  }
`;

export type EmbedOptionBarItem = 'copy' | 'delete' | 'open' | 'size' | 'styles' | 'view';
type ImageOptionBarItem =
  | 'alignment'
  | 'altText'
  | 'copy'
  | 'delete'
  | 'styles'
  | 'unlink'
  | 'width';

type OptionBarItemsByKey<T extends EmbedOptionBarItem | ImageOptionBarItem> = {
  [key in T]: (props: EmbedOptionBarItemProps) => JSX.Element;
};

type EmbedOptionBarItemsByKey = OptionBarItemsByKey<EmbedOptionBarItem>;
type ImageOptionBarItemsByKey = OptionBarItemsByKey<ImageOptionBarItem>;

export type EmbedOptionBarItemProps = {
  hideOptionsBar: () => void;
} & MenuProps;

const EMBED_OPTION_ITEMS_BY_KEY: {
  embed: EmbedOptionBarItemsByKey;
  image: ImageOptionBarItemsByKey;
} = {
  embed: {
    copy: CopyNodeButton,
    delete: DeleteButton,
    open: OpenLink,
    size: SizeDropdown,
    styles: StylesDropdown,
    view: ViewDropdown,
  },
  image: {
    alignment: AlignmentDropdown,
    altText: ImageAltText,
    copy: CopyNodeButton,
    delete: DeleteButton,
    styles: StylesDropdown,
    unlink: ImageLinkButton,
    width: WidthDropdown,
  },
};

type EmbedProps = {
  menuType: 'embed';
  items: EmbedOptionBarItem[];
  wrapperProps?: never;
};

type ImageProps = {
  menuType: 'image';
  items: ImageOptionBarItem[];
  wrapperProps: { hovering: boolean };
};

export type ItemOptionBarProps = EmbedProps | ImageProps;

type Props = {
  WrapperComponent: React.ComponentType<{ optionsBarVisible: boolean }>;
  triggerId: string;
} & ItemOptionBarProps;

const EmbedOptionsBar = ({ triggerId, items, menuType, WrapperComponent, wrapperProps }: Props) => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [showOptionsBar, setShowOptionsBar] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleToggleOptionsBar = useCallback(() => {
    setShowOptionsBar((prevState) => !prevState);
    setOpenDropdown(null);
  }, []);

  return (
    <WrapperComponent {...wrapperProps} optionsBarVisible={showOptionsBar}>
      <Poppable
        isOpen={showOptionsBar}
        item={
          <OutsideClickHandler
            onOutsideClick={(event) => {
              if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node) &&
                (event.target as HTMLElement).id !== triggerId
              ) {
                setShowOptionsBar(false);
              }
            }}
          >
            <BarWrapper data-testid='bubble-menu' id='bubble-menu-wrapper' ref={menuRef}>
              {items.map((item) => {
                const optionsByKey = EMBED_OPTION_ITEMS_BY_KEY[menuType];
                const Component =
                  menuType === 'embed'
                    ? (optionsByKey as EmbedOptionBarItemsByKey)[item as EmbedOptionBarItem]
                    : (optionsByKey as ImageOptionBarItemsByKey)[item as ImageOptionBarItem];

                return (
                  <Component
                    hideOptionsBar={() => setShowOptionsBar(false)}
                    key={item}
                    openMenuId={openDropdown}
                    setOpenMenuId={setOpenDropdown}
                  />
                );
              })}
            </BarWrapper>
          </OutsideClickHandler>
        }
        onClick={handleToggleOptionsBar}
        placement='top-end'
        trigger={<ThreeDotButton id={triggerId} />}
        usePortal
      />
    </WrapperComponent>
  );
};

export default EmbedOptionsBar;
