import camelcaseKeys, { Options as CamelcaseKeysOptions } from 'camelcase-keys';
import snakecaseKeys, { Options as SnakecaseKeysOptions } from 'snakecase-keys';

export const toCamelCase = <T>(data: T, options?: CamelcaseKeysOptions): T => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return camelcaseKeys<T, CamelcaseKeysOptions>(data, { deep: true, ...options }) as unknown as T;
};

export const toSnakeCase = <T>(data: T, options?: SnakecaseKeysOptions): T => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return snakecaseKeys<T, SnakecaseKeysOptions>(data, { deep: true, ...options }) as unknown as T;
};

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

export type KeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K];
};
