import React from 'react';
import styled from 'styled-components';

import { EmptyMediaPreview } from '../../../../../lib/gcsImages';
import { fontSm4 } from '../../../../styled/TypeSystem';

const DropzoneOutline = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  background-image: url(${EmptyMediaPreview});
  background-position-x: center;
  background-position-y: 30%;
  background-repeat: no-repeat;
  border: ${({ theme: { constants } }) => constants.borderWidthSm} dashed
    ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  height: 10.063rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
`;

const StyledInput = styled.input`
  color: inherit;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  z-index: 5;
`;

const Label = styled.span`
  position: absolute;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  ${fontSm4};
`;

type Props = {
  ariaLabel: string;
  acceptedFiles: string;
  label: string;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const FileInputDropzone = ({ ariaLabel, label, acceptedFiles, onFileChange }: Props) => {
  return (
    <DropzoneOutline>
      <StyledInput
        accept={acceptedFiles}
        aria-label={ariaLabel}
        name='file-dropzone'
        onChange={onFileChange}
        type='file'
      />
      <Label>{label}</Label>
    </DropzoneOutline>
  );
};

export default FileInputDropzone;
