import { css } from 'styled-components';

import { mediaBreakpointPxMd } from '../Breakpoint';

const Scrollbar = css`
  /* Firefox */
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: auto;
    scrollbar-color: ${({ theme: { vars } }) =>
      `${vars.foundationBase3} ${vars.foundationSurface1}`};
  }

  /* Chrome, Edge, and Safari */
  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      height: 0.75rem;
      width: 1rem;
      @media (max-width: ${mediaBreakpointPxMd}) {
        width: 0;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme: { vars } }) => vars.foundationBase3};
      border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
      border: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidth2xl} solid ${vars.foundationSurface1}`};
    }
  }
`;

export default Scrollbar;
