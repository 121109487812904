import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { AccessControl } from '../../../types/SuperShare';

export type ContentAccess = 'view' | 'edit' | 'manage' | 'owner' | 'default' | null;
export type GeneralAccess = AccessControl | null;
export type GroupId = string | null;
export type Status = 'published' | 'unpublished' | null;
export type Kind = 'reference' | 'favorites' | 'archived' | null;
export type ESignature = 'required' | 'not_required' | null;

interface SelectedFields {
  contentAccess: ContentAccess;
  groupId: GroupId;
  status: Status;
  type: Kind;
}

interface Filters {
  count?: number;
  selectedFields: SelectedFields;
  show: boolean;
}

interface PageState {
  page: number;
  filters: Filters;
}

interface CurriculumsState extends PageState {
  selectedContentType: number | null;
}

const initialState: CurriculumsState = {
  page: 1,
  filters: {
    count: 0,
    selectedFields: {
      contentAccess: null,
      groupId: null,
      status: null,
      type: null,
    },
    show: false,
  },
  selectedContentType: null,
};

const updateCount = (selectedFields: SelectedFields) =>
  _.entries(selectedFields).filter((field) => field[1] != null).length;

function handlePayload<T extends Kind | Status | ContentAccess | GroupId>(payload: T) {
  return payload?.includes('all_') ? null : payload;
}

const curriculumsSlice = createSlice({
  name: 'curriculums',
  initialState,
  reducers: {
    setPage(store, action: PayloadAction<number>) {
      store.page = action.payload;
    },
    clearFilters(store) {
      store.page = 1;
      store.filters.count = 0;
      store.filters.show = false;
      store.filters.selectedFields.groupId = null;
      store.filters.selectedFields.contentAccess = null;
      store.filters.selectedFields.type = null;
      store.filters.selectedFields.status = null;
    },
    setAllFilters(store, action: PayloadAction<SelectedFields>) {
      store.page = 1;
      store.filters.show = false;
      store.filters.selectedFields.groupId = handlePayload(action.payload.groupId);
      store.filters.selectedFields.contentAccess = handlePayload(action.payload.contentAccess);
      store.filters.selectedFields.type = handlePayload(action.payload.type);
      store.filters.selectedFields.status = handlePayload(action.payload.status);
      store.filters.count = updateCount(store.filters.selectedFields);
    },
    setSelectedContentType(store, action: PayloadAction<number>) {
      store.selectedContentType = action.payload;
    },
  },
});

export const { setPage, setAllFilters, clearFilters, setSelectedContentType } =
  curriculumsSlice.actions;

export default curriculumsSlice.reducer;
