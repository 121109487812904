import React from 'react';
import styled from 'styled-components';

import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../styled/TypeSystem';
import CharacterCounter from '../CharacterCounter';
import TooltipHoverIcon from '../Labels/ControlLabel/TooltipHoverIcon';
import { TooltipProps } from '../Labels/SharedLabelTypes';
import { CharacterCounterProps } from './FieldLabelTypes';

export type Props = {
  htmlFor?: string;
  text: string;
  required?: boolean;
  labelTruncate?: boolean;
  className?: string;
} & TooltipProps &
  CharacterCounterProps;

const LabelContainer = styled.div`
  min-height: 24px;
  display: flex;
  justify-content: space-between;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const StyledLabel = styled.label<{ truncate: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  font-weight: ${({ theme }) => theme.constants.fontSemibold};
  color: ${({ theme }) => theme.vars.textDefault};
  cursor: default;
  ${fontSm5};
  ${({ truncate }) => {
    if (truncate) return TruncatedText({});
  }};
`;

const FieldLabel = ({
  htmlFor,
  text,
  required = false,
  tooltipText,
  tooltipId,
  maxCharacters,
  currentCharacters,
  labelTruncate = true,
  className = '',
}: Props) => {
  const currentCount = Number(currentCharacters);
  return (
    <LabelContainer>
      <LabelWrapper>
        <StyledLabel className={className} htmlFor={htmlFor} truncate={labelTruncate}>
          {text} {required && <span>*</span>}
        </StyledLabel>
        {tooltipText && tooltipId && (
          <TooltipHoverIcon tooltipId={tooltipId} tooltipText={tooltipText} />
        )}
      </LabelWrapper>
      {!!maxCharacters && (
        <CharacterCounter currentCharacters={currentCount} maxCharacters={maxCharacters} />
      )}
    </LabelContainer>
  );
};

export default FieldLabel;
